import React from "react";
import "../../dashboardsso/dashboardsso.css";
import { Link } from "react-router-dom";
import LastPublications from "../../dashboard/components/LastPublications/LastPublications";
import Navbar from "../../navbar/Navbar";
import { useSelector }  from "react-redux";
import { RootState } from "../../rootReducer";
import Info from "../../dashboardsso/SsoComponents/Info/Info";
import NextPublication from "../../dashboardsso/SsoComponents/NextPublication/NextPublication";
import NetworkAdmin from "../AdminComponents/NetworkAdmin/NetworkAdmin";
import Help from "../../dashboardsso/SsoComponents/Help/Help";

function DashboardAdmin() {
    const userEmail = useSelector((state: RootState) => state.user.email);
    const email = userEmail?.split('@')[0];
    return (
        <div>
            <div className="container">
                <div className="col-4">
                    <Navbar />
                </div>
                <div className="col-8">
                    <div className="right-panel">
                        <div className="right-panel-container no-overflow">
                            <div className="title-bar title-bar-publication">
                                <div className="main-title-sso">
                                    <div className="long-box-sso">
                                        <h1>Bonjour {email ? email.charAt(0).toUpperCase() + email.slice(1) : email}</h1>
                                        <p>Bienvenue sur votre espace pour gérer la communication de vos agences sur les réseaux sociaux !
                                        </p>
                                    </div>
                                    <Link className="btn btn-block add-circle"
                                          to={'/add_publication'}>Créer une publication
                                    </Link>
                                </div>
                            </div>
                            <div className="info-container">
                                <div className="left-card">
                                    <div className="bottom-card">
                                        <LastPublications />
                                    </div>
                                </div>
                                <div className="right-card-sso">
                                    <div className="upper-card-sso">
                                        <Info />
                                    </div>
                                    <div className="bottom-cards">
                                        <div className="left-next-publication">
                                            <NextPublication />
                                        </div>
                                        <div className="right-sso">
                                            <div className="networks-sso">
                                                <NetworkAdmin />
                                            </div>
                                            <div className="help-card">
                                                <Help />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DashboardAdmin;