import React, {useState, ChangeEvent, useEffect, useCallback, useMemo, useRef} from "react";
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from "../../rootReducer";
import { isFormInvalid } from "./inputError";
import axios, {AxiosResponse} from 'axios';
import Navbar from '../../navbar/Navbar';
import EmojiPicker from 'emoji-picker-react';
import Modal from '../../modal/Modal';
import s3Object from "../../service/s3getObject";
import UploadFile from "../../uploadfile/UploadFile";
import "./publicationForm.css";
import logo from "../../assets/logo_news_factory.svg";
import networkIcons from "../../assets/network-render-icons.svg";
import publicationLogo from "../../assets/iconpubli2.svg";
import instagramLogo from "../../assets/logoinsta.svg";
import facebookLogo from "../../assets/logofb.svg";
import googleMyBusinessLogo from "../../assets/logogmb.svg";
import linkedinLogo from "../../assets/logoin.svg";
import magicPen from "../../assets/magic.svg";
import loader from "../../assets/loader.png";
import lien from "../../assets/lien.png";
import video from "../../assets/video.png";
import UploadFileSmall from "../../uploadfile/UploadFileSmall";
import { convertToDefaultTimezone, convertToISO8601WithTimezone } from "../../service/dateUtils";
import moment from "moment-timezone";
import Pagination from "../../pagination/Pagination";

interface NetworkInfo {
    isSelected: boolean;
    logo: string;
}

interface Networks {
    facebook: NetworkInfo;
    instagram: NetworkInfo;
    linkedin: NetworkInfo;
    googleMyBusiness: NetworkInfo;
}

interface PublicationNetworkData {
    id: number;
    title: string;
    content: string;
    media: string;
    extension: string;
    link: string;
    publication: string;
    publicationDate: string;
}

interface Errors {
    [key: string]: string;
}

interface NetworkResponse {
    data: {
        [key: string]: PublicationNetworkData;
    }
}

interface MediaCarousel {
    url: string | null;
    fileName: string | null;
    order: number | undefined;
    networkKey: string | undefined;
    id?: number | undefined;
}

interface Cible {
    id: number;
    name: string;
}

interface Subject {
    id: number;
    subject: string;
}

type NetworkMediaState = Record<string, MediaCarousel[]>;

const PublicationForm: React.FC = () => {
    const user = useSelector((state: RootState) => state.user.id);
    const userEmail = useSelector((state: RootState) => state.user.email);
    const groupement = useSelector((state: RootState) => state.user.groupement);
    const agencyName = useSelector((state: RootState) => state.user.agencyName);
    const userSso = useSelector((state: RootState) => state.user.isSsoUser);
    const isAdmin = useSelector((state: RootState) => state.user.isAdmin);
    const isNetworkAdmin = !isAdmin && !userSso && groupement?.id !== 1;
    const userAdminIri = user && !userSso ? `/api/user_admins/${user}`: null;
    const userSsoIri = user && userSso ? `/api/users/${user}`: null;
    const groupementIri = `/api/groupements/${groupement ? groupement.id : ''}`;
    const [minDate, setMinDate] = useState('');
    const [formErrors, setFormErrors] = useState<Errors>({});
    const [isModalOpen, setModalOpen] = useState(false);
    const [isModalIAOpen, setModalIAOpen] = useState(false);
    const [createdBy, setCreatedBy] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [networkPublicationId, setNetworkPublicationId] =
        useState<Record<string, number|null>>({
            facebook: null,
            instagram: null,
            linkedin: null,
            googleMyBusiness: null,
        });
    const [title, setTitle] = useState<string>('');
    const [networkTitles, setNetworkTitles] =
        useState<Record<string, string>>({
            facebook: '',
            instagram: '',
            linkedin: '',
            googleMyBusiness: '',
    });
    const [content, setContent] = useState<string>('');
    const [contentCurrentPosition, setContentCurrentPosition] = useState(0);
    const [networkContents, setNetworkContents] =
        useState<Record<string, string>>({
            facebook: '',
            instagram: '',
            linkedin: '',
            googleMyBusiness: '',
    });
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [media, setMedia] = useState('');
    const [medias, setMedias] = useState<MediaCarousel[]>([]);
    const [mediasToDelete, setMediasToDelete]= useState<number[]>([]);
    const [mediaNetwork, setMediaNetwork] =
        useState<Record<string, string>>({
            facebook: '',
            instagram: '',
            linkedin: '',
            googleMyBusiness: '',
    });
    const [networkMedias, setNetworkMedias] = useState<NetworkMediaState>({
        facebook: [],
        instagram: [],
        linkedin: [],
        googleMyBusiness: [],
    });
    const [mediaUrl, setMediaUrl] = useState('');
    const [extension, setExtension] = useState('');
    const [networkMedia, setNetworkMedia] =
        useState<Record<string, { mediaUrl: string; extension: string }>>({
            facebook: { mediaUrl: '', extension: '' },
            instagram: { mediaUrl: '', extension: '' },
            linkedin: { mediaUrl: '', extension: '' },
            googleMyBusiness: { mediaUrl: '', extension: '' },
        });
    const [link, setLink] = useState('');
    const [networkLink, setNetworkLink] =
        useState<Record<string, string>>({
            facebook: '',
            instagram: '',
            linkedin: '',
            googleMyBusiness: '',
    });
    const [publishDate, setPublishDate] = useState('');
    const [networkPublishDate, setNetworkPublishDate] =
        useState<Record<string, string>>({
            facebook: '',
            instagram: '',
            linkedin: '',
            googleMyBusiness: '',
    });
    const [hour, setHour] = useState('');
    const [networkHour, setNetworkHour] =
        useState<Record<string, string>>({
            facebook: '',
            instagram: '',
            linkedin: '',
            googleMyBusiness: '',
    });
    const [minute, setMinute] = useState('');
    const [networkMinute, setNetworkMinute] =
        useState<Record<string, string>>({
            facebook: '',
            instagram: '',
            linkedin: '',
            googleMyBusiness: '',
    });
    const [networks, setNetworks] = useState<Networks>({
        facebook: {isSelected: false, logo: facebookLogo},
        instagram: {isSelected: false, logo: instagramLogo},
        linkedin: {isSelected: false, logo: linkedinLogo},
        googleMyBusiness: {isSelected: false, logo: googleMyBusinessLogo},
    });
    const [showNetworkEmojiPicker, setShowNetworkEmojiPicker] =
        useState<{ [key: string]: boolean }>({
            facebook: false,
            instagram: false,
            linkedin: false,
            googleMyBusiness: false,
    });
    const [publicationData, setPublicationData] =
        useState<Record<string, any>>({
            id: '',
            title: '',
            content: '',
            media: '',
            extension: '',
            link: '',
            date: '',
            time: '',
            networks: '',
            status: '',
            createdByAdmin: '',
            createdByUser: '',
            createdAt: '',
            updatedAt: '',
            updatedBy: '',
            isGlobal: '',
            groupement: '',
    });
    const [isGlobal, setIsGlobal] = useState(false);
    const [warningMessage, setWarningMessage] = useState<string | null>(null);

    const tags = ['@email', '@phone', '@website', '@name', '@address'];
    const [selectedTag, setSelectedTag] = useState('');
    const [showWarning, setShowWarning] = useState(false);
    const navigate = useNavigate();
    const isInvalid = isFormInvalid(formErrors);
    const { publicationId } = useParams<{ publicationId: string }>();
    const [isViewOnly, setIsViewOnly] = useState(false);
    const [networkChange, setNetworkChange] = useState(false);
    const [networksLoaded, setNetworksLoaded] = useState(false);
    const [mediasLoaded, setMediasLoaded] = useState(false);
    const [mediasNetworkLoaded, setMediasNetworkLoaded] = useState(false);
    const [timezone, setTimezone] = useState('');
    const [tone, setTone] = useState([]);
    const [selectedTone, setSelectedTone] = useState<string | null>(null);
    const [cibles, setCibles] = useState<Cible[]>([]);
    const [selectedCible, setSelectedCible] = useState<Cible | null>(null);
    const [subjects, setSubjects] = useState<Subject[]>([]);
    const [selectedSubject, setSelectedSubject] = useState<Subject | null>(null);
    const [isLoadingIaModal, setIsLoadingIaModal] = useState<boolean>(false);
    const [selectedCheckbox, setSelectedCheckbox] = useState<'all' | 'selected'>('all');
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
    const [deselectedAccounts, setDeselectedAccounts] = useState<string[]>([]);
    const [totalItems, setTotalItems] = useState(0);
    const [userPublications, setUserPublications] = useState<any[]>([]);
    const [userQuotaReached, setUserQuotaReached]= useState<boolean>(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const isFetching = useRef(false);
    let itemsPerPage = 3;

    useEffect(() => {
        const today = new Date();
        const formattedDate = today.toISOString().substring(0, 10);
        setMinDate(formattedDate);
    }, []);

    useEffect(() => {
        let value = '';
        axios.get(`date`).then(resp => {
            value = resp.data.timezone;
            setTimezone(value);
        });
    }, []);

    useEffect(() => {
        if (window.location.pathname.includes('show_publication')) {
            setIsViewOnly(true);
        } else {
            setIsViewOnly(false);
        }
    }, []);

    useEffect(() => {
        if (userQuotaReached) {
            setIsErrorVisible(true);
            const timer = setTimeout(() => {
                setIsErrorVisible(false);
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [userQuotaReached]);

    const loadPublicationDetails = useCallback(async () => {
        if (!publicationId) return;
            try {
                const response = await axios.get(`/api/publications/${publicationId}`);
                const serverDate = response.data.publicationDate;
                const localDate = convertToDefaultTimezone(serverDate, timezone);
                const activeNetworks = response.data.social;

                if (response?.data.media && response?.data.extension) {
                    const filename = response.data.media + '.' + response.data.extension;
                    const s3url = s3Object(filename);
                    setMedia(s3url);
                    setMediaUrl(response.data.media);
                    setExtension(response.data.extension);
                }

                setTitle(response.data.title);
                setContent(response.data.content);
                setLink(response.data.link);
                setPublishDate(localDate.split(' ')[0]);
                setHour(localDate.split(' ')[1].split(':')[0]);
                setMinute(localDate.split(' ')[1].split(':')[1]);
                setCreatedBy(response.data.createdByAdmin ?? response.data.createdByUser);
                setCreatedAt(response.data.createdAt);
                setIsGlobal(response.data.isGlobal);

                const userPublicationsResponse = await axios.get(`/api/users_publication/${publicationId}`);
                const userPublicationsData = userPublicationsResponse.data.usersPublication;
                setUserPublications(userPublicationsData);

                if (userPublicationsData.length > 0) {
                    setSelectedCheckbox("selected");
                    const userAccounts: string[] = userPublicationsData.map(
                        (userPublication: any) => userPublication.user.split('/').pop().toString()
                    );
                    setSelectedAccounts(userAccounts);
                }

                if (!networksLoaded) {
                    const updatedNetworks = {...networks};
                    Object.keys(updatedNetworks).forEach((network) => {
                        const networkKey = network as keyof Networks;
                        updatedNetworks[networkKey].isSelected = activeNetworks.includes(networkKey);
                    });
                    setNetworks(updatedNetworks);
                    setNetworksLoaded(true);
                }

                if (!mediasLoaded) {
                    const mediasPublication = await axios.get(`/media_carousel/${publicationId}`);
                    if (mediasPublication?.data && mediasPublication?.status !== 404) {
                        const carouselData = mediasPublication?.data.medias;

                        if (carouselData && carouselData.length > 0) {
                            let updatedMedias: MediaCarousel[] = carouselData.map((data: any) => ({
                                url: data.urlMedia ? s3Object(data.urlMedia) : '',
                                fileName: data.urlMedia,
                                order: data.mediaOrder,
                                networkKey: '',
                                id: data.id,
                            }));

                            if (updatedMedias.length < 9 && !updatedMedias.some(media => media.url === '')) {
                                updatedMedias.push({ url: '', fileName: '', order: updatedMedias.length + 1, networkKey: undefined });
                            }

                            setMedias(updatedMedias);
                        } else {
                            setMedias([{ url: '', fileName: '', order: 1, networkKey: undefined }]);
                        }
                    } else {
                        setMedias([{ url: '', fileName: '', order: 1, networkKey: undefined }]);
                    }
                    setMediasLoaded(true);
                }
            } catch(error) {
                console.error('Erreur de chargement de la publication:', error);
            }

            const getNetwork = async () => {
                try {
                    const response = await axios.get<NetworkResponse>(
                        `/api/network/publication/${publicationId}`
                    );

                    for (const [network, value]  of Object.entries(response.data.data)) {
                        if (value !== null) {
                            if (value?.media && value?.extension) {
                                const filename = `${value.media}.${value.extension}`;
                                const s3url = s3Object(filename);
                                if (s3url) {
                                    setMediaNetwork(prev => ({
                                        ...prev,
                                        [network]: s3url,
                                    }));
                                }
                            }

                            if (!mediasNetworkLoaded) {
                                const mediasPublication = await axios.get(`/medias/${network}/${value.id}`);
                                const carouselData = mediasPublication.data.medias;

                                if (carouselData && carouselData.length > 0) {
                                    const updatedNetworkMedias = carouselData.map((data: any) => ({
                                        url: s3Object(data.urlMedia),
                                        fileName: data.urlMedia,
                                        order: data.mediaOrder,
                                        networkKey: network,
                                        id: data.id,
                                    }));

                                    if (updatedNetworkMedias.length < 9) {
                                        updatedNetworkMedias.push({
                                            url: '',
                                            fileName: '',
                                            order: updatedNetworkMedias.length + 1,
                                            networkKey: network
                                        });
                                    }

                                    setNetworkMedias(prev => ({
                                        ...prev,
                                        [network]: updatedNetworkMedias
                                    }));

                                } else {
                                    setNetworkMedias(prev => ({
                                        ...prev,
                                        [network]: [{url: '', fileName: '', order: 1, networkKey: network}]
                                    }));
                                }
                                setMediasNetworkLoaded(true);
                            }

                            const serverDate = value.publicationDate;
                            const localDate = moment.tz(serverDate, timezone);

                            setNetworkTitles(prev => ({
                                ...prev,
                                [network]: value.title,
                            }));
                            setNetworkContents(prev => ({
                                ...prev,
                                [network]: value.content,
                            }));
                            setNetworkMedia(prev => ({
                                ...prev,
                                [network]: {
                                    mediaUrl: value.media,
                                    extension: value.extension,
                                },
                            }));
                            setNetworkLink(prev => ({
                                ...prev,
                                [network]: value.link,
                            }));
                            setNetworkPublishDate(prev => ({
                                ...prev,
                                [network]: localDate.format('YYYY-MM-DD'),
                            }));
                            setNetworkHour(prev => ({
                                ...prev,
                                [network]: localDate.format('HH'),
                            }));
                            setNetworkMinute(prev => ({
                                ...prev,
                                [network]: localDate.format('mm'),
                            }));
                            setNetworkPublicationId(prev => ({
                                ...prev,
                                [network]: value.id
                            }))
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            };
        await getNetwork();
    }, [publicationId, timezone, networksLoaded, mediasLoaded, networks, mediasNetworkLoaded]);

    useEffect(() => {
        const today = new Date();
        const formattedDate = today.toISOString().substring(0, 10);
        setMinDate(formattedDate);
    }, []);

    const config = useMemo(() => ({
        timeout: 60000,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }), []);

    const uploadMedia = useCallback(async (file: File): Promise<string> => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post('/api/upload', formData, config);
            return response.data.url;
        } catch (error) {
            console.error('Erreur lors du téléchargement du média', error);
            throw error;
        }
    }, [config]);

    const selectedNetworks = useMemo(() => Object.entries(networks).filter(
        ([, info]) => info.isSelected).map(([key]) => key), [networks]);

    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const networkTextareasRef = useRef<{ [key: string]: HTMLTextAreaElement | null }>({
        facebook: null,
        instagram: null,
        linkedin: null,
        googleMyBusiness: null,
    });

    const [networkContentCurrentPosition, setNetworkContentCurrentPosition] = useState<{ [key: string]: number }>({
        facebook: 0,
        instagram: 0,
        linkedin: 0,
        googleMyBusiness: 0,
    });

    const handleNetworkCursorPosition = (network: string, e: any) => {
        setNetworkContentCurrentPosition(prev => ({
            ...prev,
            [network]: e.target.selectionStart,
        }));
    };

    const onEmojiClick = (emojiObject: any, event: any) => {
        const emoji = emojiObject.emoji;
        if (emoji) {
            const newText = content.slice(0, contentCurrentPosition) + emoji + content.slice(contentCurrentPosition);
            setContent(newText);

            setTimeout(() => {
                if (textareaRef.current) {
                    textareaRef.current.selectionStart = contentCurrentPosition + 2;
                    textareaRef.current.selectionEnd = contentCurrentPosition + 2;
                    textareaRef.current.focus();
                }
            }, 0);
            setShowEmojiPicker(false);
        } else {
            console.error("L'emoji sélectionné n'est pas défini");
        }
    };

    const handleCursorPosition = (e: any) => {
        setContentCurrentPosition(e.target.selectionStart);
    };

    const handleOpenModal = useCallback((actionType: 'save' | 'archive' | 'edit' | 'duplicate') => {
        setModalOpen(true);
        const isEdit = actionType === 'edit';
        const date = dateFormatter(new Date());
        const status = actionType === 'save' || actionType === 'edit' || actionType === 'duplicate' ? 1 : 0;
        const dateTime = convertToISO8601WithTimezone(publishDate, `${hour}:${minute}:00`, timezone);

        setPublicationData({
            title: title,
            content: content,
            media: mediaUrl,
            extension: extension,
            link: link,
            publicationDate: dateTime,
            social: selectedNetworks,
            status: status,
            createdByAdmin: isAdmin && !userSso ? (isEdit ? createdBy : userAdminIri) : userAdminIri,
            createdByUser: userSso ? (isEdit ? createdBy : userSsoIri) : null,
            createdAt: isEdit ? createdAt : date,
            updatedBy: isEdit ? userEmail : null,
            updatedAt: isEdit ? date : null,
            isGlobal: isAdmin ? (isEdit ? isGlobal : isAdmin) : (!isAdmin && !userSso && !isNetworkAdmin),
            groupement: groupementIri,
        });
    }, [publishDate, hour, minute, timezone, title, content, mediaUrl, extension, link, selectedNetworks, isAdmin, userSso, createdBy, userAdminIri, userSsoIri, createdAt, userEmail, isGlobal, isNetworkAdmin, groupementIri]);

    const handleCloseModal = () => {
        setModalOpen(false);
    }

    const handleCloseModalIA = () => {
        setSelectedCible(null);
        setSelectedSubject(null);
        setSelectedTone(null);
        setModalIAOpen(false);
    }

    useEffect(() => {
        const fetchCibles = async () => {
            try {
                const cibleResp = await axios.get('/api/ia_cibles');
                setCibles(cibleResp?.data['hydra:member']);
            } catch (error) {
                console.error("Error when fetching cibles: ", error);
            }
        }

        fetchCibles();
    }, []);

    useEffect(() => {
        if (selectedCible) {
            const fetchSubjects = async () => {
                try {
                    const subjectResp = await axios.get(`/api/ia_subjects?cible=${selectedCible.id}`);
                    setSubjects(subjectResp?.data['hydra:member']);
                    setSelectedSubject(null);
                } catch (error) {
                    console.error("Error fetching subjects: ", error);
                }
            };

            fetchSubjects();
        }
    }, [selectedCible]);

    const handleCibleChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedId = parseInt(event.target.value, 10);
        const cible = cibles.find(c => c.id === selectedId) || null;
        setSelectedCible(cible);
    };

    const handleSubjectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedId = parseInt(event.target.value, 10);
        const subject = subjects.find(s => s.id === selectedId) || null;
        setSelectedSubject(subject);
    };

    const handleToneChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedTone(event.target.value);
    };

    const onNetworkEmojiClick = (emojiObject: any, event: any, network: keyof typeof networkContents) => {
        const emoji = emojiObject.emoji;
        const currentPosition = networkContentCurrentPosition[network];
        const currentContent = networkContents[network];
        const newText = currentContent.slice(0, currentPosition) + emoji + currentContent.slice(currentPosition);

        setNetworkContents(prev => ({
            ...prev,
            [network]: newText,
        }));

        setTimeout(() => {
            if (networkTextareasRef.current[network]) {
                networkTextareasRef.current[network]!.selectionStart = currentPosition + emoji.length;
                networkTextareasRef.current[network]!.selectionEnd = currentPosition + emoji.length;
                networkTextareasRef.current[network]!.focus();
            }
        }, 0);

        setShowNetworkEmojiPicker(prev => ({
            ...prev,
            [network]: false,
        }));
    };

    const toggleEmojiPicker = () => setShowEmojiPicker(!showEmojiPicker);

    const toggleNetworkEmojiPicker = (selectedNetwork: string) => {
        setShowNetworkEmojiPicker(prevState => {
            const newState = { ...prevState };
            Object.keys(newState).forEach(network => {
                newState[network] = network === selectedNetwork ? !prevState[network] : false;
            });
            return newState;
        });
    };

    const handleAddTag = (tag: string) => {
        const newText = content.slice(0, contentCurrentPosition) + tag + content.slice(contentCurrentPosition);
        setContent(newText);

        setTimeout(() => {
            if (textareaRef.current) {
                textareaRef.current.selectionStart = contentCurrentPosition + 3;
                textareaRef.current.selectionEnd = contentCurrentPosition + 3;
                textareaRef.current.focus();
            }
        }, 0);
    };

    const handleTagChange = (e: React.ChangeEvent<HTMLSelectElement>, networkKey?: string) => {
        const tag = e.target.value;
        if (tag && networkKey) {
            handleAddNetworkTag(networkKey, tag);
            setSelectedTag('');
        } else if (tag) {
            setSelectedTag(tag);
            handleAddTag(tag);
            setSelectedTag('');
        }
    };

    const handleAddNetworkTag = (network: keyof typeof networkContents, tag: string) => {
        const currentPosition = networkContentCurrentPosition[network];
        const currentContent = networkContents[network];
        const newText = currentContent.slice(0, currentPosition) + tag + currentContent.slice(currentPosition);
        setNetworkContents(prev => ({
            ...prev,
            [network]: newText,
        }));

        setTimeout(() => {
            if (networkTextareasRef.current[network]) {
                const newCursorPosition = currentPosition + tag.length;
                networkTextareasRef.current[network]!.selectionStart = newCursorPosition;
                networkTextareasRef.current[network]!.selectionEnd = newCursorPosition;
                networkTextareasRef.current[network]!.focus();
            }
        }, 0);
    };

    const isGlobalPublicationReady = useCallback((): boolean => {
        return !!title && !!content && !!publishDate && !!hour && !!minute;
    }, [title, content, publishDate, hour, minute]);

    const toggleNetwork = useCallback((network: keyof Networks) => {
        if (!networks[network].isSelected && !isGlobalPublicationReady()) {
            setShowWarning(true);
            setWarningMessage("Veuillez d'abord renseigner le titre, le contenu, la date et l'heure de la publication globale.");
            setTimeout(() => {
                setShowWarning(false);
                setWarningMessage(null);
            }, 3000);
            return;
        }
        setNetworks(prevNetworks => {
            const isCurrentlySelected = prevNetworks[network].isSelected;
            const updatedNetworks = {
                ...prevNetworks,
                [network]: {
                    ...prevNetworks[network],
                    isSelected: !isCurrentlySelected,
                },
            };

            if (!isCurrentlySelected) {
                const capitalizedNetwork = network.charAt(0).toUpperCase() + network.slice(1);
                setNetworkTitles(prevTitle => ({
                    ...prevTitle,
                    [network]: title,
                }));
                setNetworkContents(prevContents => ({
                    ...prevContents,
                    [network]: content,
                }));
                if (network === 'googleMyBusiness' && (media && extension === "mp4")) {
                    setWarningMessage("Google My Business ne prend pas en charge les vidéos. Veuillez choisir une image.");
                    setMediaNetwork(prev => ({
                        ...prev,
                        [network]: '',
                    }));
                    setNetworkMedia(prev => ({
                        ...prev,
                        [network]: {
                            mediaUrl: '',
                            extension: '',
                        }
                    }));
                    setNetworkMedias(prev => ({
                        ...prev,
                        [network]: []
                    }))
                    setShowWarning(true);
                    setTimeout(() => {
                        setShowWarning(false);
                    }, 5000);
                } else {
                    setMediaNetwork(prevMedia => ({
                        ...prevMedia,
                        [network]: extension === 'mp4' ? video : media,
                    }));
                    setNetworkMedia(prevNetworkMedia => ({
                        ...prevNetworkMedia,
                        [network]: {
                            mediaUrl: mediaUrl,
                            extension: extension
                        }
                    }));
                    setNetworkMedias(prev => ({
                        ...prev,
                        [network]: medias.map(media => ({ ...media }))
                    }));
                }
                if (network === 'instagram' && link) {
                    setNetworkLink(prev => ({
                        ...prev,
                        [network]: ''
                    }));
                    setMediaNetwork(prev => ({
                        ...prev,
                        [network]: '',
                    }));
                    setWarningMessage(`${capitalizedNetwork} n'accepte pas les liens. Pensez à ajouter au moins une photo/vidéo.`);
                    setShowWarning(true);
                    setTimeout(() => {
                        setShowWarning(false);
                    }, 5000);
                } else if (network === 'googleMyBusiness' && link) {
                    setNetworkLink(prev => ({
                        ...prev,
                        [network]: ''
                    }));
                    setMediaNetwork(prev => ({
                        ...prev,
                        [network]: '',
                    }));
                    setWarningMessage(`${capitalizedNetwork} n'accepte pas les liens. Pensez à ajouter au moins une photo.`);
                    setShowWarning(true);
                    setTimeout(() => {
                        setShowWarning(false);
                    }, 5000);
                } else if (network === 'linkedin' && link) {
                    setNetworkLink(prev => ({
                        ...prev,
                        [network]: link,
                    }));
                    setMediaNetwork(prev => ({
                        ...prev,
                        [network]: '',
                    }));
                    setWarningMessage(`Une image est nécessaire pour la publication d'un lien sur ${capitalizedNetwork}. Pensez à ajouter au moins une photo.`);
                    setShowWarning(true);
                    setTimeout(() => {
                        setShowWarning(false);
                    }, 5000);
                } else if (link) {
                    setNetworkLink(prev => ({
                        ...prev,
                        [network]: link,
                    }));
                    setMediaNetwork(prev => ({
                        ...prev,
                        [network]: lien,
                    }));
                }
                setNetworkPublishDate(prevDate => ({
                    ...prevDate,
                    [network]: publishDate,
                }));
                setNetworkHour(prevHour => ({
                    ...prevHour,
                    [network]: hour,
                }));
                setNetworkMinute(prevHour => ({
                    ...prevHour,
                    [network]: minute,
                }))
            }

            return updatedNetworks;
        });
    }, [content, extension, hour, isGlobalPublicationReady, link, media, mediaUrl, medias, minute, networks, publishDate, title]);

    const memoizedNetworks = useMemo(() => networks, [networks]);

    useEffect(() => {
        loadPublicationDetails();
    }, [loadPublicationDetails, memoizedNetworks]);

    useEffect(() => {
        if (networkChange) {
            setNetworkChange(false);
        }
    }, [networkChange]);

    const handleHourChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
        setHour(e.target.value);
    }, []);

    const handleNetworkHourChange = useCallback((network: keyof typeof networkContents, e: ChangeEvent<HTMLSelectElement>) => {
        setNetworkHour(prev => ({
            ...prev,
            [network]: e.target.value
        }));
    }, []);

    const handleMinuteChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
        setMinute(e.target.value);
    }, []);

    const handleNetworkMinuteChange = useCallback((network: keyof typeof networkContents, e: ChangeEvent<HTMLSelectElement>) => {
        setNetworkMinute(prev => ({
            ...prev,
            [network]: e.target.value
        }));
    }, []);

    const handleDateChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setPublishDate(e.target.value);
    }, []);

    const handleNetworkDateChange = useCallback((network: keyof typeof networkContents, e: ChangeEvent<HTMLInputElement>) => {
        setNetworkPublishDate(prev => ({
            ...prev,
            [network]: e.target.value
        }));
    }, []);

    const handleNetworkLink = useCallback((network: keyof typeof networkContents, e: ChangeEvent<HTMLInputElement>) => {
        const link = e.target.value;
        if (link && network !== 'linkedin') {
            setNetworkLink(prev => ({
                ...prev,
                [network]: link
            }));
            setMediaNetwork(prev => ({
                ...prev,
                [network]: lien
            }));
        } else if (link && network === 'linkedin') {
            setNetworkLink(prev => ({
                ...prev,
                [network]: link
            }));
            setMediaNetwork(prev => ({
                ...prev,
                [network]: ''
            }));
        }else {
            setNetworkLink(prev => ({
                ...prev,
                [network]: ''
            }));
            setMediaNetwork(prev => ({
                ...prev,
                [network]: ''
            }));
        }
    }, []);

    const handleMediaChange = useCallback(async (e: ChangeEvent<HTMLInputElement>, networkKey?: string, index?: number) => {
        e.preventDefault();
        const files = e.target.files;
        if (files && files[0]) {
            const file = files[0];
            const allowedTypes = ['image/jpeg', 'image/jpg', 'video/mp4'];

            if (!allowedTypes.includes(file.type)) {
                setShowWarning(true);
                setWarningMessage(`Format de fichier non supporté. Seuls les fichiers jpg et mp4 sont acceptés.`);
                setTimeout(() => {
                    setShowWarning(false);
                    setWarningMessage(null);
                }, 5000);
                return;
            }
            const fileName = await uploadMedia(file);
            const s3Url = s3Object(fileName);
            const splitName = fileName.split('.');

            if (!!mediaUrl && splitName[1] === 'jpg' && typeof index === 'number' && !networkKey) {
                const updatedMedias = [...medias];
                if (updatedMedias[index]) {
                    updatedMedias[index] = { ...updatedMedias[index], url: s3Url, fileName: fileName, order: index + 1, networkKey: '' };
                } else {
                    updatedMedias.push({ url: s3Url, fileName: fileName, order: index + 1, networkKey: '' });
                }
                if (updatedMedias.length < 9 && !updatedMedias.some(media => media.url === '')) {
                    updatedMedias.push({ url: '', fileName: '', order: updatedMedias.length + 1, networkKey: '' });
                }
                setMedias(updatedMedias);
            } else if (!!mediaUrl && splitName[1] === 'mp4' && typeof index === 'number' && !networkKey) {
                setMedias([]);
            } else if (networkKey) {
                if (typeof index === 'number' && splitName[1] === 'jpg' && !!networkMedia[networkKey].mediaUrl && networkKey !== 'googleMyBusiness') {
                    const updatedNetworkMedias = [...(networkMedias[networkKey] || [])];
                    if (typeof index === 'number' && updatedNetworkMedias[index]) {
                        updatedNetworkMedias[index] = {url: s3Url, fileName: fileName, order: index + 1, networkKey};
                    } else {
                        updatedNetworkMedias.push({
                            url: s3Url,
                            fileName: fileName,
                            order: updatedNetworkMedias.length + 1,
                            networkKey
                        });
                    }
                    if (updatedNetworkMedias.length < 9 && !updatedNetworkMedias.some(media => media.url === '')) {
                        updatedNetworkMedias.push({
                            url: '',
                            fileName: '',
                            order: updatedNetworkMedias.length + 1,
                            networkKey: networkKey
                        });
                    }
                    setNetworkMedias(prev => ({
                        ...prev,
                        [networkKey]: updatedNetworkMedias
                    }));
                } else {
                    setNetworkMedia(prev => ({
                        ...prev,
                        [networkKey]: {
                            mediaUrl: splitName[0],
                            extension: splitName[1],
                        },
                    }));
                    setMediaNetwork(prev => ({
                        ...prev,
                        [networkKey]: splitName[1] === 'mp4' ? video : s3Url,
                    }));

                    if (splitName[1] === 'mp4') {
                        setNetworkMedias(prev => ({
                            ...prev,
                            [networkKey] : []
                        }));
                    }
                }
            } else {
                setMediaUrl(splitName[0]);
                setExtension(splitName[1]);
                setMedia(s3Url);
                const newMedias = [...medias, { url: '', fileName: '', order: medias.length + 1, networkKey: undefined }];

                if (newMedias.length < 9) {
                    setMedias(newMedias);
                }
            }
        } else {
            if (networkKey) {
                setNetworkMedia(prev => ({
                    ...prev,
                    [networkKey]: { mediaUrl: '', extension: '' },
                }));
                setMediaNetwork(prev => ({
                    ...prev,
                    [networkKey]: '',
                }));
            } else {
                setMedia('');
                setMediaUrl('');
                setExtension('');
                setMedias([]);
                setNetworkMedias({});
            }
        }
    }, [mediaUrl, medias, networkMedia, networkMedias, uploadMedia]);

    const resetMedia = useCallback((networkKey?: string, index?: number) => {

        if (networkKey) {
            if (typeof index === 'number') {
                const mediaToDelete = networkMedias[networkKey][index];
                if (mediaToDelete && mediaToDelete.id !== undefined) {
                    setMediasToDelete(prev => [...prev, mediaToDelete.id!]);
                }
                setNetworkMedias(prev => ({
                    ...prev,
                    [networkKey]: prev[networkKey].filter((_, i) => i !== index)
                }));
            } else {
                setNetworkMedia(prev => ({
                    ...prev,
                    [networkKey]: { mediaUrl: '', extension: '' }
                }));
                setMediaNetwork(prev => ({
                    ...prev,
                    [networkKey]: ''
                }));
            }
        } else if (typeof index === 'number') {
            const mediaToDelete = medias[index];
            if (mediaToDelete && mediaToDelete.id !== undefined) {
                setMediasToDelete(prev => [...prev, mediaToDelete.id!]);
            }
            setMedias(prev => {
                const updatedMedias = [...prev];
                updatedMedias.splice(index, 1);
                if (updatedMedias.length < 9 && !updatedMedias.some(media => media.url === '')) {
                    updatedMedias.push({ url: '', fileName: '', order: updatedMedias.length + 1, networkKey: undefined });
                }
                return updatedMedias;
            });
        } else {
            setMedia('');
            setMediaUrl('');
            setExtension('');
            setMedias([{ url: '', fileName: '', order: 1, networkKey: undefined }]);
        }
    }, [medias, networkMedias]);

    const handleLinkChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setLink(e.target.value);
        setMedia(lien);
    }, []);

    const dateFormatter = (date: Date) => {
        const month = date.toLocaleString("fr-FR", {month: "2-digit"});
        const day = date.toLocaleString("fr-FR", {day: "2-digit"});
        return `${date.getFullYear()}-${month}-${day}`;
    }

    const handleTitleChange = useCallback((network: keyof typeof networkTitles, value: string) => {
        setNetworkTitles(prev => ({
            ...prev,
            [network]: value,
        }));
    }, []);

    const handleContentChange = useCallback((network: keyof typeof networkContents, value: string) => {
        setNetworkContents(prev => ({
            ...prev,
            [network]: value,
        }));
    }, []);

    const handleOpenIAModal = useCallback(() => {
        setModalIAOpen(true);
    }, []);

    const getCurrentUserRequest = useCallback(async (user: any): Promise<any> => {
        let url = userSso ? `/api/users/${user}` : `/api/user_admins/${user}`;
        try {
            const response = await axios.get(url);
            return response.data;
        } catch (error) {
            console.error(`Error fetching user request for user ${userEmail}:`, error);
            throw error;
        }
    }, [userEmail, userSso]);

    const openIAModal = useCallback(async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setUserQuotaReached(false);
        const currentUser = await getCurrentUserRequest(user);
        if (currentUser.requestIA < currentUser.quotaIA) {
            try {
                const toneResp = await axios.get('/api/ia_tones');
                setTone(toneResp?.data['hydra:member']);
            } catch (error) {
                console.error(error);
            }

            handleOpenIAModal();
        } else {
            setUserQuotaReached(true);
        }
    }, [getCurrentUserRequest, handleOpenIAModal, user]);

    const closeIaGeneration = () => {
        setIsLoadingIaModal(false);
    }

    const handleTextGeneration = useCallback(async () => {
        if (selectedCible && selectedSubject && selectedTone) {
            setContent('');
            const payload = {
                cible: selectedCible.name,
                subject: selectedSubject.subject,
                tone: selectedTone,
            };
            handleCloseModalIA();
            setIsLoadingIaModal(true);
            try {
                const currentUser = await getCurrentUserRequest(user);
                if (currentUser.requestIA < currentUser.quotaIA) {
                    let url = userSso ? `/api/users/${user}` : `/api/user_admins/${user}`;
                    await axios.patch(url, {
                        requestIA: currentUser.requestIA + 1
                    }, {
                        headers: {
                            'Content-Type': 'application/merge-patch+json',
                        }
                    });
                }
            } catch (error) {
                console.error("Error when update requestIa ", error);
            }

            const response = await axios({
                method: 'POST',
                url: '/api/generate-text',
                data: payload
            });
            try {
                setContent(response.data.generated_text);
                setIsLoadingIaModal(false);
            } catch (error) {
                console.error('Error submitting generate-text:', error);
                handleCloseModalIA();
            }
        } else {
            console.error('Please select a cible, subject, and tone.');
        }
    }, [getCurrentUserRequest, selectedCible, selectedSubject, selectedTone, user, userSso]);

    const handleCheckboxChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const { id } = event.target;
        setSelectedCheckbox(id === 'all_account' ? 'all' : 'selected');
        setSearchQuery('');
        setSearchResults([]);
        setSelectedAccounts([]);
    }, []);

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const performSearch = useCallback(async () => {
        if (isFetching.current) {
            return;
        }

        if (searchQuery.trim() !== '' && searchQuery.length >= 3) {
            isFetching.current = true;
            const adminUrl = `?page=${currentPage}&itemsPerPage=${itemsPerPage}&isActive=true&search=${searchQuery}`;
            const networkGrpUrl = `?page=${currentPage}&itemsPerPage=${itemsPerPage}&isActive=true&groupement.id=${groupement ? groupement.id : ''}&search=${searchQuery}`;

            try {
                const response = await axios.get('/api/users'
                    + (isAdmin ? adminUrl : networkGrpUrl));
                setTotalItems(response.data['hydra:totalItems']);
                const total = (Math.ceil(totalItems / itemsPerPage));
                setTotalPage(total > 0 ? total : 1);
                setSearchResults(response.data["hydra:member"]);
            } catch (error) {
                console.error('Error fetching search results:', error);
            } finally {
                isFetching.current = false;
            }
        } else {
            setSearchResults([]);
        }
    }, [currentPage, groupement, isAdmin, itemsPerPage, searchQuery, totalItems]);

    useEffect(() => {
        if (selectedCheckbox === 'selected') {
            performSearch();
        }
    }, [selectedCheckbox, performSearch]);

    const handleAccountSelect = useCallback((event: ChangeEvent<HTMLInputElement>, accountId: string) => {
        const idStr = accountId.toString();
        if (event.target.checked) {
            setSelectedAccounts(prev => {
                if (!prev.includes(idStr)) {
                    return [...prev, idStr];
                }
                return prev;
            });
            setDeselectedAccounts(prev => prev.filter(id => id !== idStr));
        } else {
            setSelectedAccounts(prev => prev.filter(id => id !== idStr));
            setDeselectedAccounts(prev => {
                if (!prev.includes(idStr)) {
                    return [...prev, idStr];
                }
                return prev;
            });
        }
    }, []);

    const resetFormStates = useCallback(() => {
        setTitle('');
        setContent('');
        setMediaUrl('');
        setExtension('');
        setLink('');
        setPublishDate('');
        setMedia('');
        setHour('');
        setMinute('');
        setNetworks({
            facebook: {isSelected: false, logo: facebookLogo},
            instagram: {isSelected: false, logo: instagramLogo},
            linkedin: {isSelected: false, logo: linkedinLogo},
            googleMyBusiness: {isSelected: false, logo: googleMyBusinessLogo},
        });
        setMedias([]);
        setContentCurrentPosition(0);
        setSelectedAccounts([]);
        setDeselectedAccounts([]);
    }, []);

    const resetNetworkFormStates = useCallback(() => {
        setNetworkTitles({
            facebook: '',
            instagram: '',
            linkedin: '',
            googleMyBusiness: '',
        });
        setNetworkContents({
            facebook: '',
            instagram: '',
            linkedin: '',
            googleMyBusiness: '',
        });
        setNetworkLink({
            facebook: '',
            instagram: '',
            linkedin: '',
            googleMyBusiness: '',
        });
        setNetworkPublishDate({
            facebook: '',
            instagram: '',
            linkedin: '',
            googleMyBusiness: '',
        });
        setNetworkHour({
            facebook: '',
            instagram: '',
            linkedin: '',
            googleMyBusiness: '',
        });
        setNetworkMinute({
            facebook: '',
            instagram: '',
            linkedin: '',
            googleMyBusiness: '',
        });
        setNetworkPublicationId({
            facebook: null,
            instagram: null,
            linkedin: null,
            googleMyBusiness: null,
        });
        setMediaNetwork({
            facebook: '',
            instagram: '',
            linkedin: '',
            googleMyBusiness: '',
        });
        setNetworkMedia({
            facebook: {
                mediaUrl: '',
                extension: '',
            },
            instagram: {
                mediaUrl: '',
                extension: '',
            },
            linkedin: {
                mediaUrl: '',
                extension: '',
            },
            googleMyBusiness: {
                mediaUrl: '',
                extension: '',
            },
        });
        setNetworkMedias({});
    }, []);

    const networkMapping = useMemo(() => ({
        facebook: 'fbs',
        instagram: 'instas',
        linkedin: 'linkedins',
        googleMyBusiness: 'gmbs',
    }), []);

    const isAnyNetworkSelected = useCallback((): boolean => {
        return Object.values(networks).some(network => network.isSelected);
    }, [networks]);

    const isDateTimeSetted = useCallback((): boolean => {
        return !!publishDate && !!hour && !!minute;
    }, [hour, minute, publishDate]);

    const isUserSelected = useCallback((): boolean => {
        return selectedCheckbox === 'all' || (selectedAccounts.length > 0) || userSso;
    }, [selectedAccounts.length, selectedCheckbox, userSso]);

    const requireMediaForSelectedNetworks = useCallback((): boolean => {
        const instaSelected = networks.instagram.isSelected;
        const gmbSelected = networks.googleMyBusiness.isSelected;
        const linkedinSelected = networks.linkedin.isSelected;

        if (instaSelected) {
            const instaMediaExists = networkMedia.instagram.mediaUrl !== ''
                || (networkMedias.instagram && networkMedias.instagram.some(m => m.url !== ''));

            if (!instaMediaExists) return false;
        }

        if (gmbSelected) {
            const gmbMediaExists = networkMedia.googleMyBusiness.mediaUrl !== ''
                || (networkMedias.googleMyBusiness && networkMedias.googleMyBusiness.some(m => m.url !== ''));

            if (!gmbMediaExists) return false;
        }

        if (linkedinSelected && networkLink.linkedin) {
            const linkedinMediaExistForLink = (networkMedia.linkedin.mediaUrl !== '' && networkLink.linkedin !== '');

            if (!linkedinMediaExistForLink) return false;
        }

        return true;
    }, [networks, networkMedia, networkMedias, networkLink]);

    const areAllSelectedNetworksDateTimeSet = useCallback((): boolean => {
        const selected = Object.entries(networks).filter(([, info]) => info.isSelected).map(([key]) => key);
        for (const net of selected) {
            if (!networkPublishDate[net] || !networkHour[net] || !networkMinute[net]) {
                return false;
            }
        }
        return true;
    }, [networks, networkPublishDate, networkHour, networkMinute]);

    const handleSubmit = useCallback(async (actionType: 'save' | 'archive' | 'edit') => {
        const date = new Date();
        const isEdit = actionType === 'edit';
        const status = actionType === 'save' || actionType === 'edit' ? 1 : 0;
        handleCloseModal();

        const endPoint = publicationId ? `/api/publications/${publicationId}` : '/api/publications';
        const method = publicationId ? 'patch' : 'post';

        try {
            const response = await axios({
                method: method,
                url: endPoint,
                data: JSON.stringify(publicationData),
                headers: {
                    'Content-Type': isEdit ? 'application/merge-patch+json' : 'application/ld+json',
                },
            });

            if (response.statusText === "Created" || response.statusText === 'OK' || response.status === 201 || response.status === 200 ) {
                 const publicationId = response.data.id;
                 const urlPublication = `api/publications/${publicationId}`;

                 if (selectedCheckbox === 'selected' && selectedAccounts.length > 0 && !userSso) {
                    for (const account of selectedAccounts) {
                        const urlUser = `api/users/${account}`;
                        const userAlreadyLinked = userPublications.some(up => up.user.split('/').pop() === account);

                        if (!userAlreadyLinked) {
                            try {
                                await axios.post('api/user_publications', {
                                    user: urlUser,
                                    publication: urlPublication
                                }, {
                                    headers: {
                                        'Content-Type': 'application/ld+json',
                                    },
                                });
                            } catch (error) {
                                console.error('Error when post user_publications ', error);
                            }
                        }
                    }
                 } else if (userSso && !isEdit) {
                     try {
                         const urlUser = `api/users/${user}`;
                         await axios.post('api/user_publications', {
                             user: urlUser,
                             publication: urlPublication
                         }, {
                             headers: {
                                 'Content-Type': 'application/ld+json',
                             },
                         });
                     } catch (error) {
                         console.error('Error when post user_publications ', error);
                     }
                 } else if (!userSso && !isAdmin && isNetworkAdmin ) {
                     try {
                         const grpId = groupement ? groupement.id : null;

                         if (grpId) {
                             const users = await axios.get(`api/users?isActive=true&groupement.id=${grpId}`);

                             for (const user of users.data['hydra:member']) {
                                 const urlUser = `api/users/${user.id}`;
                                 await axios.post('api/user_publications', {
                                     user: urlUser,
                                     publication: urlPublication
                                 }, {
                                     headers: {
                                         'Content-Type': 'application/ld+json',
                                     },
                                 });
                             }
                         }
                     } catch (error) {
                         console.error('Error when post user_publications for Network ', error);
                     }
                 }

                if (deselectedAccounts.length > 0) {
                    deselectedAccounts.forEach(account => {
                        const userPublicationId = userPublications.find(up => up.user.split('/').pop() === account).id;
                            axios.delete(`/api/user_publications/${userPublicationId}`);
                    });
                }

                if (mediasToDelete.length > 0) {
                    for (const mediaId of mediasToDelete) {
                        await axios.delete(`/api/publication_medias/${mediaId}`);
                    }
                    setMediasToDelete([]);
                }

                 if (medias && medias.length > 0) {
                    const formattedMedias = medias.map(media => ({
                        urlMedia: media.fileName,
                        mediaOrder: media.order,
                        network: media.networkKey ?? null,
                        publicationId: publicationId,
                        id: media.id ?? null,
                    }));

                    for (const media of formattedMedias) {
                         if (media.urlMedia) {
                            await axios({
                                method: 'post',
                                url: `/api/publication_medias`,
                                data: JSON.stringify(media),
                                headers: {
                                    'Content-Type': 'application/ld+json',
                                },
                            });
                        }
                    }
                }

                 await Promise.all(Object.entries(networks).map(async ([networkKey, networkInfo]) => {

                    if (networkInfo.isSelected) {
                        const apiNetworkKey = networkMapping[networkKey as keyof typeof networkMapping];
                        const dateTimeNetwork = convertToISO8601WithTimezone(
                            `${networkPublishDate[networkKey]}`,
                            `${networkHour[networkKey]}:${networkMinute[networkKey]}`
                        , timezone);
                        const publicationForNetwork = {
                            publication: publicationId ? `/api/publications/${publicationId}` : null,
                            title: networkTitles[networkKey],
                            content: networkContents[networkKey],
                            media: networkMedia[networkKey].mediaUrl ?? null,
                            extension: networkMedia[networkKey].extension ?? null,
                            link: networkLink[networkKey] ?? null,
                            publicationDate: dateTimeNetwork,
                            updatedBy: userEmail,
                            updatedAt: date,
                            status: status,
                        };

                        try {
                            let response: AxiosResponse<any, any>;
                            if (isEdit && networkPublicationId[networkKey]) {
                                response = await axios({
                                    method: 'patch',
                                    url: `/api/publication_${apiNetworkKey}/${networkPublicationId[networkKey]}`,
                                    data: JSON.stringify(publicationForNetwork),
                                    headers: {
                                        'Content-Type': 'application/merge-patch+json',
                                    },
                                });
                            } else {
                                response = await axios({
                                    method: 'post',
                                    url: `/api/publication_${apiNetworkKey}`,
                                    data: JSON.stringify(publicationForNetwork),
                                    headers: {
                                        'Content-Type': 'application/ld+json',
                                    },
                                });
                            }

                            if (response.statusText === 'Created' || response.statusText === 'OK' || response.status === 201 || response.status === 200) {
                                const publicationNetworkId = response.data.id;
                                if (networkMedias[networkKey] && networkMedias[networkKey].length > 0) {
                                    const formattedMedias = networkMedias[networkKey].map(media => ({
                                        urlMedia: media.fileName,
                                        mediaOrder: media.order,
                                        network: networkKey,
                                        publicationId: publicationNetworkId,
                                        id: media.id ?? null,
                                    }));

                                    for (const media of formattedMedias) {
                                        if (!!media.urlMedia && networkKey !== 'googleMyBusiness') {
                                            await axios({
                                                method: 'post',
                                                url: `/api/publication_medias`,
                                                data: JSON.stringify(media),
                                                headers: {
                                                    'Content-Type': 'application/ld+json',
                                                },
                                            });
                                        }
                                    }
                                }
                                resetFormStates();
                                resetNetworkFormStates();
                                navigate("/publications")
                            }
                        } catch (error) {
                            console.error(`${networkKey} publication error: `, error);
                        }
                    }
                }));
            }
        } catch (error: unknown) {
            if (axios.isAxiosError(error) && error.message) {
                if (error.response && error.response.data && error.response.data.errors) {
                    setFormErrors(error.response.data.errors);
                }
            }
            console.error(`Error on ${isEdit ? 'updating' : 'creating'} publication : `, error);
        }
    }, [publicationId, publicationData, selectedCheckbox, selectedAccounts, userSso, isAdmin, isNetworkAdmin, deselectedAccounts, mediasToDelete, medias, networks, userPublications, user, groupement, networkMapping, networkPublishDate, networkHour, networkMinute, timezone, networkTitles, networkContents, networkMedia, networkLink, userEmail, networkPublicationId, networkMedias, resetFormStates, resetNetworkFormStates, navigate]);

    const handlePreSave = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        // event.preventDefault();
        if (!isAnyNetworkSelected()) {
            setShowWarning(true);
            setWarningMessage("Veuillez sélectionner au moins un réseau pour continuer.");
            setTimeout(() => {
                setShowWarning(false);
                setWarningMessage(null);
            }, 3000);
            return;
        }

        if (!isDateTimeSetted()) {
            setShowWarning(true);
            setWarningMessage("Veuillez sélectionner une date et une heure globale pour continuer.");
            setTimeout(() => {
                setShowWarning(false);
                setWarningMessage(null);
            }, 3000);
            return;
        }

        if (!areAllSelectedNetworksDateTimeSet()) {
            setShowWarning(true);
            setWarningMessage("Veuillez définir la date et l'heure pour chaque réseau sélectionné.");
            setTimeout(() => {
                setShowWarning(false);
                setWarningMessage(null);
            }, 3000);
            return;
        }

        if (!requireMediaForSelectedNetworks()) {
            setShowWarning(true);
            setWarningMessage("Instagram et/ou GoogleMyBusiness et/ou Linkedin sont sélectionnés, veuillez ajouter au moins un média.");
            setTimeout(() => {
                setShowWarning(false);
                setWarningMessage(null);
            }, 5000);
            return;
        }

        setShowWarning(false);
        handleOpenModal('save');
    }, [areAllSelectedNetworksDateTimeSet, handleOpenModal, isAnyNetworkSelected, isDateTimeSetted, requireMediaForSelectedNetworks]);

    const handlePreEdit = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (!isAnyNetworkSelected()) {
            setShowWarning(true);
            setWarningMessage("Veuillez sélectionner au moins un réseau pour continuer.");
            setTimeout(() => {
                setShowWarning(false);
                setWarningMessage(null);
            }, 3000);
            return;
        }

        if (!isDateTimeSetted()) {
            setShowWarning(true);
            setWarningMessage("Veuillez sélectionner une date et une heure globale pour continuer.");
            setTimeout(() => {
                setShowWarning(false);
                setWarningMessage(null);
            }, 3000);
            return;
        }

        if (!areAllSelectedNetworksDateTimeSet()) {
            setShowWarning(true);
            setWarningMessage("Veuillez définir la date et l'heure pour chaque réseau sélectionné.");
            setTimeout(() => {
                setShowWarning(false);
                setWarningMessage(null);
            }, 3000);
            return;
        }

        if (!requireMediaForSelectedNetworks()) {
            setShowWarning(true);
            setWarningMessage("Instagram et/ou GoogleMyBusiness sont sélectionnés, veuillez ajouter au moins un média.");
            setTimeout(() => {
                setShowWarning(false);
                setWarningMessage(null);
            }, 5000);
            return;
        }

        setShowWarning(false);
        handleOpenModal('edit');
    }, [isAnyNetworkSelected, isDateTimeSetted, areAllSelectedNetworksDateTimeSet, requireMediaForSelectedNetworks, handleOpenModal]);

    const handleSaveClick = useCallback(() => {
        handleSubmit('save');
    }, [handleSubmit]);

    const handleEditClick = useCallback(() => {
        handleSubmit('edit');
    }, [handleSubmit]);

    const handleArchiveClick = useCallback(() => {
        handleSubmit('archive');
    }, [handleSubmit]);

    return (
        <div className="container">
            <div className="col-4">
                <Navbar />
            </div>
            <div className="col-8">
                <div className="right-panel">
                    <div className="right-panel-container">
                        <div className="title-bar">
                            <div className="main-title">
                                {publicationId ? (
                                    <>
                                        <h1>
                                            {isViewOnly
                                                ? "Visualisation de la publication"
                                                : publicationId ? "Éditer la publication" : "Nouvelle publication"}
                                        </h1>

                                        <p>
                                            {isViewOnly ? "" : publicationId ?
                                                "Éditez et paramétrez votre publication" :
                                                "Créez et paramétrez votre publication"}
                                        </p>
                                    </>
                                ) : null}
                            </div>
                            <div>
                                <Link className="btn-login btn-block btn-cancel"
                                      to={'/publications'}>Annuler</Link>
                            </div>
                        </div>
                        {showWarning && warningMessage && (
                            <p className="warning-message">{warningMessage}</p>
                        )}
                        <div id="accordion">
                            <form>
                                {!publicationId && (
                                    <>
                                        <div key="headingOne" className="card-new-publication">
                                        <div className="card-header" id="headingOne">
                                            <h2 className={'no-arrow'}>
                                                <img src={publicationLogo}
                                                     style={{
                                                         width: 32,
                                                         height: 32,
                                                     }}
                                                     alt="logo publication"/>
                                                Ma publication
                                            </h2>
                                        </div>
                                        <div id="collapseOne"
                                             className="collapse show"
                                             aria-labelledby="headingOne"
                                             data-parent="#accordion">
                                            <div className="publi-group">
                                                <label htmlFor="title">Titre de votre publication</label>
                                                <input
                                                    type="text"
                                                    name="title"
                                                    value={title}
                                                    onChange={(e) => setTitle(e.target.value)}
                                                    placeholder="Titre de votre publication"
                                                    required
                                                    readOnly={isViewOnly}
                                                />
                                                {formErrors.title &&
                                                    <div className="error-message">{formErrors.title}</div>}
                                            </div>
                                            <div className="publi-group half">
                                                <div className="half-left">
                                                    <label htmlFor="media">Ajouter un média</label>
                                                    <UploadFile media={media}
                                                                extension={extension}
                                                                handleMediaChange={handleMediaChange}
                                                                resetMedia={resetMedia}
                                                                disabled={!!link || isViewOnly}
                                                    />
                                                    <div className="additional-media">
                                                        {mediaUrl && extension === 'jpg' && medias.length >= 0 &&
                                                            medias.map((mediaItem, index) => (
                                                                <div key={index + 'base'} className="media-container">
                                                                    <label htmlFor={`mediaCarousel-${index}`}
                                                                           className={'carousel-media-title'}>Image {index + 2}</label>
                                                                    <UploadFileSmall
                                                                        key={index + 'base'}
                                                                        media={mediaItem.url || ''}
                                                                        index={index}
                                                                        handleMediaChange={(e) => handleMediaChange(e, undefined, index)}
                                                                        resetMedia={() => resetMedia(undefined, index)}
                                                                        disabled={medias.some(media => !mediaUrl) || medias.length > 9 || isViewOnly}
                                                                    />
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                                <div className="half-right">
                                                    <label htmlFor="content">Contenu de la publication</label>
                                                    <textarea
                                                        ref={textareaRef}
                                                        name="content"
                                                        value={content}
                                                        onChange={(e) => setContent(e.target.value)}
                                                        onClick={handleCursorPosition}
                                                        onKeyUp={handleCursorPosition}
                                                        placeholder="Contenu de la publication"
                                                        required
                                                        readOnly={isViewOnly}
                                                    />
                                                    {formErrors.content &&
                                                        <div className="error-message">{formErrors.content}</div>}
                                                    {isErrorVisible &&
                                                        <div className="error-message">Votre quota de génération de
                                                            contenu par IA est atteint. Merci de contacter le
                                                            support.</div>}
                                                    <div className="group-btn">
                                                        <button
                                                            type={"button"}
                                                            disabled={isViewOnly}
                                                            onClick={openIAModal}>
                                                            <img src={magicPen} alt="baguette magique"/>
                                                            Générer du contenu avec l'IA
                                                        </button>
                                                        {!userSso && (
                                                            <select onChange={handleTagChange}
                                                                    value={selectedTag}
                                                                    disabled={isViewOnly}
                                                                    className={'select-balise-publication'}
                                                            >
                                                                <option value="">@ Balises</option>
                                                                {tags.map((tag, index) => (
                                                                    <option key={index} value={tag}>{tag}</option>
                                                                ))}
                                                            </select>
                                                        )}
                                                        <button type="button"
                                                                className={'emoji'}
                                                                disabled={isViewOnly}
                                                                onClick={toggleEmojiPicker}>
                                                            Emoji
                                                        </button>
                                                        {showEmojiPicker && (
                                                            <div className="emoji-picker-popup">
                                                                <EmojiPicker onEmojiClick={onEmojiClick}/>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="publi-group third">
                                                <div className="publi-link">
                                                    <label htmlFor="link">Ajouter un lien</label>
                                                    <input
                                                        name="link"
                                                        type="url"
                                                        value={link}
                                                        onChange={handleLinkChange}
                                                        placeholder="Ajouter un lien"
                                                        disabled={!!media && media !== lien}
                                                        readOnly={isViewOnly}
                                                    />
                                                </div>
                                                <div className="publi-date">
                                                    <label htmlFor="date">Date de publication</label>
                                                    <input
                                                        name="date"
                                                        type="date"
                                                        value={publishDate}
                                                        onChange={handleDateChange}
                                                        min={minDate}
                                                        required
                                                        readOnly={isViewOnly}
                                                    />
                                                </div>
                                                <div className="publi-date">
                                                    <label htmlFor="time">Heure de publication</label>
                                                    <div className={'select-time'}>
                                                        <select required
                                                                disabled={isViewOnly}
                                                                value={hour} onChange={handleHourChange}>
                                                            <option value='' key={'heure'}>Heure</option>
                                                            {[...Array(24).keys()].map((h) => (
                                                                <>
                                                                    <option key={h} value={h < 10 ? `0${h}` : h}>
                                                                        {h < 10 ? `0${h}` : h}
                                                                    </option>
                                                                </>
                                                            ))}
                                                        </select>
                                                        <span className={'time-colon'}>:</span>
                                                        <select required
                                                                disabled={isViewOnly}
                                                                value={minute} onChange={handleMinuteChange}>
                                                            <option value=''>Minutes</option>
                                                            {/* Options pour les minutes */}
                                                            <option value="00">00</option>
                                                            <option value="30">30</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                )}
                                <>
                                    {Object.entries(networks).map(([network, networkData]) => {
                                        const capitalizedNetwork = network.charAt(0).toUpperCase() + network.slice(1);
                                        return (
                                            <>
                                                <div key={network}
                                                     className={'card-new-publication'}>
                                                    <div className="card-header">
                                                        <h2
                                                            className={networkData.isSelected ? `${network}-open` : ''}
                                                            onClick={(e) => {
                                                                toggleNetwork(network as keyof Networks);
                                                            }}
                                                            style={{cursor: 'pointer'}}
                                                        >
                                                            <img src={networkData.logo}
                                                                 alt={`${network} logo`}
                                                                 style={{
                                                                     width: 32,
                                                                     height: 32,
                                                                     filter: networkData.isSelected ? 'none' : 'grayscale(100%)'
                                                                 }}
                                                            />
                                                            <span>{capitalizedNetwork}</span>
                                                        </h2>
                                                    </div>
                                                    <div>
                                                        {networkData.isSelected && (
                                                            <div id={`collapse${network}`}
                                                                 className="collapse">
                                                                <div className="publi-group">
                                                                    <label
                                                                        htmlFor="title">{`Titre de votre publication ${capitalizedNetwork}`}</label>
                                                                    <input
                                                                        type="text"
                                                                        name="title"
                                                                        value={networkTitles[network]}
                                                                        onChange={(e) => handleTitleChange(
                                                                            network as keyof typeof networkTitles,
                                                                            e.target.value)}
                                                                        placeholder={`Titre de votre publication ${capitalizedNetwork}`}
                                                                        required={networkData.isSelected === 1}
                                                                        disabled={isViewOnly}
                                                                    />
                                                                </div>
                                                                <div className="publi-group half">
                                                                    <div className="half-left">
                                                                        <label htmlFor="media">Ajouter un média</label>
                                                                        <UploadFile
                                                                            media={mediaNetwork[network]}
                                                                            extension={networkMedia[network].extension}
                                                                            handleMediaChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                                                if (e.target.files && e.target.files[0]) {
                                                                                    handleMediaChange(e, network);
                                                                                }
                                                                            }}
                                                                            resetMedia={() => resetMedia(network)}
                                                                            //active le formulaire d'envoi d'image uniquement pour instagram et linkedin en cas de lien present. et le désactive pour les autres si un lien est present
                                                                            disabled={((!!networkLink[network] && network !== 'linkedin' && network !== 'instagram') && (!!lien)) || isViewOnly}
                                                                            networkKey={network}
                                                                        />
                                                                        {network !== 'googleMyBusiness' && (
                                                                            <div className="additional-media">
                                                                                {networkMedia[network].mediaUrl
                                                                                    && networkMedia[network].extension === 'jpg'
                                                                                    && networkMedias[network].map((mediaItem, index) => (
                                                                                        <div key={index + network}
                                                                                             className="media-container">
                                                                                            <label
                                                                                                htmlFor={`mediaCarousel-${index}`}
                                                                                                className={'carousel-media-title'}>Image {index + 2}</label>
                                                                                            <UploadFileSmall
                                                                                                key={index + network}
                                                                                                media={mediaItem.url || ''}
                                                                                                index={index}
                                                                                                handleMediaChange={(e) => handleMediaChange(e, network, index)}
                                                                                                resetMedia={() => resetMedia(network, index)}
                                                                                                networkKey={network}
                                                                                                disabled={networkMedias[network].length > 9 || isViewOnly}
                                                                                            />
                                                                                        </div>
                                                                                    ))}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className="half-right">
                                                                        <label htmlFor="content">Contenu de la
                                                                            publication
                                                                            pour {capitalizedNetwork}</label>
                                                                        <textarea
                                                                            ref={(el) => networkTextareasRef.current[network] = el}
                                                                            name="content"
                                                                            value={networkContents[network]}
                                                                            onChange={(e) => handleContentChange(
                                                                                network as keyof typeof networkContents, e.target.value
                                                                            )}
                                                                            onClick={(e) => handleNetworkCursorPosition(network, e)}
                                                                            onKeyUp={(e) => handleNetworkCursorPosition(network, e)}
                                                                            placeholder={`Contenu de la publication pour ${capitalizedNetwork}`}
                                                                            disabled={isViewOnly}
                                                                        />
                                                                        <div className="group-btn">
                                                                            {!userSso && (
                                                                                <select value={selectedTag}
                                                                                        onChange={(e) => handleTagChange(e, network)}
                                                                                        title={"@ Balises"}
                                                                                        className={'select-balise-publication'}
                                                                                        disabled={isViewOnly}
                                                                                >
                                                                                    <option value="">@ Balises</option>
                                                                                    {tags.map((tag, index) => (
                                                                                        <option key={index} value={tag}>
                                                                                            {tag}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                            )}
                                                                            <button type="button"
                                                                                    className={'emoji'}
                                                                                    disabled={isViewOnly}
                                                                                    onClick={() => toggleNetworkEmojiPicker(network)}>
                                                                            Emoji
                                                                        </button>
                                                                        {Object.keys(showNetworkEmojiPicker).map(network =>
                                                                            showNetworkEmojiPicker[network] && (
                                                                                <div className="emoji-picker-popup emoji">
                                                                                    <EmojiPicker key={'emoji-' + network}
                                                                                                 onEmojiClick={(emojiObject, event) => onNetworkEmojiClick(emojiObject, event, network)}/>
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="publi-group third">
                                                                <div className="publi-link">
                                                                    <label htmlFor="link">Ajouter un lien</label>
                                                                    {(network === 'linkedin' || network === 'facebook') &&
                                                                        (
                                                                            <input
                                                                                name="link"
                                                                                type="url"
                                                                                value={networkLink[network]}
                                                                                onChange={(e) => handleNetworkLink(network as keyof typeof networkContents, e)}
                                                                                placeholder={`Ajouter un lien pour ${capitalizedNetwork}`}
                                                                                // idem que pour les images : instagram désactivé par defaut ainsi que GMB, linkedin accepte les liens avec images, sinon désactivé si un media est sélectionné
                                                                                disabled={(!!mediaNetwork[network] && mediaNetwork[network] !== lien) || isViewOnly}
                                                                            />
                                                                        )}
                                                                    {(network === 'instagram' || network === 'googleMyBusiness') && (
                                                                        <p>{`${capitalizedNetwork} ne prend en charge pas les liens`}</p>
                                                                    )}
                                                                </div>
                                                                <div className="publi-date">
                                                                    <label htmlFor="date">Date de publication</label>
                                                                    <input
                                                                        name="date"
                                                                        type="date"
                                                                        value={networkPublishDate[network]}
                                                                        onChange={(e) => handleNetworkDateChange(network as keyof typeof networkContents, e)}
                                                                        min={minDate}
                                                                        required={networkData.isSelected === 1}
                                                                        disabled={isViewOnly}
                                                                    />
                                                                </div>
                                                                <div className="publi-date">
                                                                    <label htmlFor="time">Heure de publication</label>
                                                                    <div className={'select-time'}>
                                                                        <select
                                                                            required={networkData.isSelected === 1}
                                                                            disabled={isViewOnly}
                                                                            value={networkHour[network]}
                                                                            onChange={(e) => handleNetworkHourChange(
                                                                                network as keyof typeof networkContents, e
                                                                            )}>
                                                                            <option value=''>Heure</option>
                                                                            {[...Array(24).keys()].map((h) => (
                                                                                <option key={h}
                                                                                        value={h < 10 ? `0${h}` : h}>
                                                                                    {h < 10 ? `0${h}` : h}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                        <span className={'time-colon'}>:</span>
                                                                        <select
                                                                            required={networkData.isSelected === 1}
                                                                            disabled={isViewOnly}
                                                                            value={networkMinute[network]}
                                                                            onChange={(e) => handleNetworkMinuteChange(
                                                                                network as keyof typeof networkContents, e
                                                                            )}>
                                                                            <option value=''>Minutes</option>
                                                                            <option value="00">00</option>
                                                                            <option value="30">30</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    );
                                }) }
                                </>
                                {!isViewOnly && (
                                    <div className="publi-button half">
                                        {isFormInvalid(formErrors) && (
                                            <p className="warning-message">Il y a des erreurs dans votre formulaire.</p>
                                        )}
                                        <button className="btn btn-block btn-save"
                                                type="button"
                                                onClick={handleArchiveClick}>Enregistrer le brouillon
                                        </button>
                                        <button
                                            disabled={isInvalid}
                                            className="btn btn-block btn-publish"
                                            type="button"
                                            onClick={publicationId ? handlePreEdit : handlePreSave}>
                                            {publicationId ? " Éditer mon post" : "Publier mon post"}
                                        </button>
                                    </div>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={handleCloseModal}
                   className={userSso ? '' : (selectedCheckbox === 'selected' ? 'modal-large' : '')}>
                <img className='modal-logo' src={logo} alt="logo News Factory"/>
                <span className='modal-title'>Aperçu de la publication</span>
                <span className='modal-subtitle'>Confirmez-vous la programmation de la publication suivante ?</span>
                <span className='modal-text-date'>Le {publishDate} à {hour}:{minute}</span>
                <div className="modal-body">
                    <div className='modal-network-render'>
                        <span className="modal-network-render-account">{userSso ? agencyName : userEmail}</span>
                        <img className="modal-network-render-picture" src={extension === 'mp4' ? video : media}
                             alt="illustration"/>
                        <img className="modal-network-render-icons" src={networkIcons} alt="icons"/>
                        <span className="modal-network-render-content">{content}</span>
                        <span className="modal-network-render-links">{link}</span>
                    </div>
                    {!userSso && (
                        <>
                            <div className="checkbox-group">
                                <span className='modal-title modal-title-account'>Vous souhaitez programmer cette publication sur : </span>
                                <div className="checkbox-item">
                                    <input type="checkbox"
                                        name="all_account"
                                        id="all_account"
                                        checked={selectedCheckbox === 'all'}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label htmlFor="all_account" className="modal-subtitle">L'ensemble des comptes</label>
                                </div>
                                <div className="checkbox-item">
                                    <input type="checkbox"
                                        name="selected_account"
                                        id="selected_account"
                                        checked={selectedCheckbox === 'selected'}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label htmlFor="selected_account" className="modal-subtitle">Sélectionner un ou plusieurs comptes</label>
                                </div>
                            </div>
                            {selectedCheckbox === 'selected' && (
                                <>
                                    <input
                                        type="text"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                        placeholder="Rechercher des comptes"
                                    />
                                    {searchResults.length > 0 && (
                                        <div className="modal-table-container">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Nom de l'agence</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Téléphone</th>
                                                        <th scope="col">Adresse</th>
                                                        <th scope="col">Publier</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {searchResults.map((result, index) => (
                                                    <tr key={index}>
                                                        <th scope="row">{result.agencyName}</th>
                                                        <td>{result.email}</td>
                                                        <td>{result.phoneNumber}</td>
                                                        <td>{result.address}</td>
                                                        <td className={"checkbox-cell"}>
                                                            <input
                                                                type="checkbox"
                                                                onChange={(e) => handleAccountSelect(e, result.id.toString())}
                                                                checked={(selectedAccounts.includes(result.id.toString()))}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                    <Pagination
                                        currentPage={currentPage}
                                        totalPage={totalPage}
                                        previous={() => setCurrentPage(p => p - 1)}
                                        next={() => setCurrentPage(p => p + 1)}
                                    />
                                </>
                            )}
                        </>
                    )}
                </div>
                <div className="modal-actions">
                    <button className="btn btn-modal btn-modal-cancel" onClick={handleCloseModal}>Annuler</button>
                    <button className={`btn btn-modal btn-modal-publish btn-${isUserSelected()}`}
                            onClick={publicationId ? handleEditClick : handleSaveClick}
                            disabled={!isUserSelected()}>
                        {publicationId ? "Confirmer et editer" : "Confirmer et publier"}
                    </button>
                </div>
            </Modal>
            <Modal isOpen={isModalIAOpen} onClose={handleCloseModalIA}>
                <span className='modal-title-ia'>Générer un texte via l'IA</span>
                <label htmlFor="cible" className={'small-modal'}>Votre cible :</label>
                <select name="cible" id="cible" onChange={handleCibleChange}>
                    <option value="">Choisir votre cible</option>
                    {cibles.map((c: any, index) => (
                        <option key={index} value={c.id}>{c.name}</option>
                    ))}
                </select>
                {selectedCible && (
                    <>
                        <label htmlFor="subject" className={'small-modal'}>Le sujet :</label>
                        <select name="sujet" id="sujet" onChange={handleSubjectChange}
                                value={selectedSubject?.id || ''}>
                            <option value="">Choisir votre sujet</option>
                            {subjects.map((s: any, index) => (
                                <option key={index} value={s.id}>{s.subject}</option>
                            ))}
                        </select>
                    </>
                )}
                {selectedCible && selectedSubject && (
                    <>
                        <label htmlFor="tone" className={'small-modal'}>Ton de la publication :</label>
                        <select name="tone" id="tone" onChange={handleToneChange} value={selectedTone || ''}>
                            <option value={""}>Choisir le ton</option>
                            {tone.map((t: any, index) => (
                                <option key={index} value={t.tone}>{t.tone}</option>
                            ))}
                        </select>
                    </>
                )}
                <div className="modal-actions modal-buttons-space">
                    <button
                        onClick={handleCloseModalIA}
                        className={"btn btn-modal btn-modal-cancel"}>Annuler
                    </button>
                    <button
                        onClick={handleTextGeneration}
                        className={"btn btn-modal btn-modal-publish"}>Générer le texte
                    </button>
                </div>
            </Modal>
            <Modal isOpen={isLoadingIaModal} onClose={closeIaGeneration}>
                <span className='modal-title-ia'>Générer un texte via l'IA</span>
                <img src={loader} alt={"loader"} className={"modal-loader"}/>
                <p>Génération en cours ...</p>
            </Modal>
        </div>
    );
}

export default PublicationForm;