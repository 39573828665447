import React, {useEffect, useState} from "react";
import network from "../../../assets/sso/networks.svg";
import axios from "axios";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import fb from '../../../assets/sso/fb.svg';
import insta from '../../../assets/sso/insta.svg';
import gmb from '../../../assets/sso/gmb.svg';
import linkedin from '../../../assets/sso/linkedin.svg';
import "./networkAdmin.css";

interface UserData {
    id: number;
    active: boolean;
    address?: string;
    agencyName?: string;
    email: string;
    phoneNumber?: string;
    website?: string;
    linkedin?: string;
    insta?: string;
    gmb?: string;
    fb?: string;
    pendingActivation?: boolean;
    quotaIA?: number;
    requestIA?: number;
    groupement?: string;
    groupementName?: string;
    linkedToAgency?: boolean;
}
const NetworkAdmin: React.FC = () => {
    const [users, setUsers] = useState<[UserData] | null>(null);
    const [totalUsers, setTotalUsers] = useState(0);
    const adminGroupement = useSelector((state: RootState) => state.user.groupement);
    const grpId = adminGroupement ? adminGroupement.id : '';
    const url = `/api/users?groupement.id=${String(grpId)}&isActive=1`;


    useEffect(() => {
        const fetchUser = async () => {
            const response = await axios.get(url);
            setUsers(response.data['hydra:member']);
            setTotalUsers(response.data['hydra:totalItems']);
        };

        fetchUser();
    }, [url]);

    function getTotalConnexions(users: UserData[])
    {
        let totalFb = 0;
        let totalInsta = 0;
        let totalGmb = 0;
        let totalLinkedin = 0;

        for (const user of users) {
            if (user.fb) totalFb++;
            if (user.insta) totalInsta++;
            if (user.gmb) totalGmb++;
            if (user.linkedin) totalLinkedin++;
        }

        return {
            facebook: totalFb,
            instagram: totalInsta,
            googleMyBusiness: totalGmb,
            linkedin: totalLinkedin
        };
    }

    const totals = users ? getTotalConnexions(users) : { facebook: 0, instagram: 0, googleMyBusiness: 0, linkedin: 0};

    return <div>
        <div className="box-title">
            <img src={network} alt="networks icon"/>
            <div className="title">Mes réseaux</div>
        </div>
        {users ? (
            <>
                <div className="networks-connected">
                    <div className="network">
                        <img src={fb} alt="logo facebook"/>
                        <span className="network-name">FACEBOOK</span>
                        <span className={`network-status ${totals.facebook === totalUsers ? 'status-complete' : 'status-incomplete'}`}>
                            {totals.facebook} / {totalUsers}
                        </span>
                    </div>
                    <div className="network">
                        <img src={insta} alt="logo instagram"/>
                        <span className="network-name">INSTAGRAM</span>
                        <span
                            className={`network-status ${totals.instagram === totalUsers ? 'status-complete' : 'status-incomplete'}`}>
                            {totals.instagram} / {totalUsers}
                        </span>
                    </div>
                    <div className="network">
                        <img src={linkedin} alt="logo linkedin"/>
                        <span className="network-name">LINKEDIN</span>
                        <span className={`network-status ${totals.linkedin === totalUsers ? 'status-complete' : 'status-incomplete'}`}>
                            {totals.linkedin} / {totalUsers}
                        </span>
                    </div>
                    <div className="network">
                        <img src={gmb} alt="logo gmb"/>
                        <span className="network-name">GOOGLE MY BUSINESS</span>
                        <span className={`network-status ${totals.googleMyBusiness === totalUsers ? 'status-complete' : 'status-incomplete'}`}>
                            {totals.googleMyBusiness} / {totalUsers}
                        </span>
                    </div>
                </div>
            </>
        ) : (
            <p>Chargement...</p>
        )}
    </div>
}

export default NetworkAdmin;