import React from "react";
import "./navbar.css";
import {Link, useLocation} from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import homeIcon from "../assets/home.svg";
import publiIcon from "../assets/iconpubli.svg";
import userIcon from "../assets/iconuser.svg";
import logoutIcon from "../assets/iconlogout.svg";
import settingIcon from "../assets/setting.svg";
import Logout from "../logout/Logout"

function Navbar() {
    const location = useLocation();
    const path = location.pathname;
    const unselectedColor = 'brightness(50%) grayscale(100%)';
    const selectedColor ='var(--primary-color)';
    const isAdmin = useSelector(
        (state: RootState) => state.user.isAdmin
    );
    const isSsoUser = useSelector(
        (state: RootState) => state.user.isSsoUser
    );
    const userId = useSelector(
        (state: RootState) => state.user.userMyId
    );
    const groupement = useSelector((state: RootState) => state.user.groupement);
    const isNetworkAdmin = !isAdmin && !isSsoUser && groupement?.id !== 1;

    const handleRedirect = async () => {
        const token = process.env.REACT_APP_MY_API_TOKEN;
        const myApiUrl = `${process.env.REACT_APP_MY_API_URL}/api/my_connect_user/${userId}?token=${token}`;

        try {
            const response = await fetch(myApiUrl, {
                method: 'GET',
                redirect: 'manual',
            });
            const redirectUrl = response.url;

            if (redirectUrl) {
                localStorage.removeItem('userToken');
                localStorage.removeItem('userEmail');
                localStorage.removeItem('agencyName');
                localStorage.removeItem('roles');
                localStorage.removeItem('userId');
                localStorage.removeItem('isAdmin');
                localStorage.removeItem('quota');
                localStorage.removeItem('isSsoUser');
                localStorage.removeItem('userMyId');
                localStorage.removeItem('groupement');
                localStorage.removeItem('linkedToAgency');
                window.location.href = redirectUrl
            } else {
                console.error('Échec de la connexion SSO', response);
            }
        } catch (error) {
            console.error('Erreur lors de la requête SSO :', error);
        }
    }

    return (
        <div className="navbar-container">
            <div className="menu">
                <div className="menu-link">
                    <img src={homeIcon}
                         alt="icon home"
                         style={{
                             filter: path==='/dashboard' ? '' : unselectedColor
                        }}/>
                    {isSsoUser ? (
                        <Link to={'/dashboard_agency'}
                              className="text-wrapper"
                              style={{
                                  color: path==='/dashboard' ? selectedColor : ''
                              }}>Accueil</Link>
                    ) : (
                        <Link to={isNetworkAdmin ? '/dashboard_admin' : '/dashboard'}
                              className="text-wrapper"
                              style={{
                                  color: path==='/dashboard' ? selectedColor : ''
                              }}>Accueil</Link>
                    )}
                </div>
                <div className="menu-link">
                    <img src={publiIcon}
                         alt="icon publications"
                         style={{
                             filter: path==='/publications' ? '' : unselectedColor
                         }}/>
                    <Link to={'/publications'}
                          className="text-wrapper"
                          style={{
                              color: path==='/publications' ? selectedColor : ''
                          }}>Mes publications</Link>
                </div>
                {!isSsoUser && (
                    <div className="menu-link">
                        <img src={userIcon}
                             alt="icon user"
                             style={{
                                 filter: path === '/users' ? '' : unselectedColor
                             }}/>
                        <Link to={'/users'}
                              className="text-wrapper"
                              style={{
                                  color: path === '/users' ? selectedColor : ''
                              }}>Utilisateurs</Link>
                    </div>
                )}
            </div>
            {isAdmin && (
                <div className="menu-link">
                    <img src={settingIcon}
                         className={"icon-setting"}
                         alt="icon setting"
                         style={{
                             filter: path === '/admin' ? '' : unselectedColor
                         }}/>
                    <Link to={'/administration'}
                          className={"text-wrapper"}
                          style={{
                              color: path === '/admin' ? selectedColor : ''
                          }}>Administration</Link>
                </div>
            )}
            {!isSsoUser ? (
                <div className="sub-menu">
                    <div className="menu-link">
                        <img src={logoutIcon} alt="icon logout"/>
                        <Logout/>
                    </div>
                </div>
            ) : (
                <div className="sub-menu">
                    <div className="menu-link">
                        <button onClick={handleRedirect} className={"redirect-btn text-wrapper"}>
                            <img src={logoutIcon} alt="icon logout"/>
                            <span className={"redirect-btn-text"}>
                               Retour sur my.previsite
                            </span>
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Navbar;