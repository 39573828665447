import React from "react";
import "./info.css";
import share from "../../../assets/sso/share.svg";
import ia from "../../../assets/sso/ia.svg";
import choice from "../../../assets/sso/choix.svg";
import archive from "../../../assets/sso/ico_archivage 1.svg";
import secure from "../../../assets/secure.svg";
import {RootState} from "../../../rootReducer";
import {useSelector} from "react-redux";

const Info: React.FC = () => {
    const isSsoUser = useSelector((state: RootState) => state.user.isSsoUser);

    return <div className="info-sso-bloc">
        <div>
            <p className="title-info-sso info-sso">
                <span className="black-text">Grâce à<br/>
                    votre espace,</span><br/>
                vous pouvez
            </p>
        </div>
        <div className="info-part">
            <img src={share} alt="share icon"/>
            {isSsoUser ? (
                <p className="info-sso">
                    Gérer la diffusion<br/>
                    des contenus<br/>
                    Newsfactory
                </p>
            ) : ( <p className="info-sso">
                Diffuser du<br/>
                contenu au sein<br/>
                de votre réseau
            </p> )
            }
        </div>
        <div className="info-part">
            <img src={ia} alt="ia icon"/>
            {isSsoUser ? (
                <p className="info-sso">
                    Créer vos<br/>
                    publications<br/>
                    avec l’IA
                </p>
            ) : (
                <p className="info-sso">
                    Créer & planifier<br/>
                    vos publications<br/>
                    avec l’IA
                </p> )
            }
        </div>
        <div className="info-part">
            {isSsoUser ? (
                <>
                    <img src={choice} alt="choice icon"/>
                    <p className="info-sso">
                        Sélectionner les<br/>
                        réseaux de<br/>
                        diffusion
                    </p>
                </>
            ) : (
                <>
                    <img src={archive} alt="archive icon"/>
                    <p className="info-sso">
                        Gérer la diffusion<br/>
                        des contenus<br/>
                        Newsfactory
                    </p>
                </>
                )
            }
        </div>
        <div className="info-part">
            {isSsoUser ? (
                <>
                    <img src={archive} alt="archive icon"/>
                    <p className="info-sso">
                        Consulter tous<br/>
                        vos derniers<br/>
                        posts
                    </p>
                </>
            ) : (
                <>
                    <img src={secure} alt="secure icon"/>
                    <p className="info-sso">
                        Suivre l'état des<br/>
                        connexions de<br/>
                        mon réseau
                    </p>
                </>
            )
            }
        </div>
    </div>
}

export default Info;