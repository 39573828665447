import React, {useEffect, useState} from "react";
import network from "../../../assets/sso/networks.svg";
import axios from "axios";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import fb from '../../../assets/sso/fb.svg';
import insta from '../../../assets/sso/insta.svg';
import gmb from '../../../assets/sso/gmb.svg';
import linkedin from '../../../assets/sso/linkedin.svg';
import connected from '../../../assets/sso/check.svg';
import notConnected from '../../../assets/sso/close.svg';

interface UserData {
    id: number;
    active: boolean;
    address?: string;
    agencyName?: string;
    email: string;
    phoneNumber?: string;
    website?: string;
    linkedin?: string;
    insta?: string;
    gmb?: string;
    fb?: string;
    pendingActivation?: boolean;
    quotaIA?: number;
    requestIA?: number;
    groupement?: string;
    groupementName?: string;
    linkedToAgency?: boolean;
}
const Networks: React.FC = () => {
    const [user, setUser] = useState<UserData | null>(null);
    const userId = useSelector((state: RootState) => state.user.id);
    const url = `/api/users`;

    useEffect(() => {
        const fetchUser = async () => {
            const response =  await axios.get(url + '/' + userId);
            const user = response.data;
            setUser(user);
        }
        fetchUser();
    }, [url, userId]);

    return <div>
        <div className="box-title">
            <img src={network} alt="networks icon"/>
            <div className="title">Mes réseaux</div>
        </div>
        {user ? (
            <>
                <div className="networks-connected">
                    <div className="network">
                        <img src={fb} alt="logo facebook"/>
                        <span className="network-name">FACEBOOK</span>
                        <span>
                            {user.fb ? (
                                <img src={connected} alt="connected"/>
                            ) : (
                                <img src={notConnected} alt="not connected"/>
                            )}
                        </span>
                    </div>
                    <div className="network">
                        <img src={insta} alt="logo instagram"/>
                        <span className="network-name">INSTAGRAM</span>
                        <span>
                            {user.insta ? (
                                <img src={connected} alt="connected"/>
                            ) : (
                                <img src={notConnected} alt="not connected"/>
                            )}
                        </span>
                    </div>
                    <div className="network">
                        <img src={linkedin} alt="logo linkedin"/>
                        <span className="network-name">LINKEDIN</span>
                        <span>{user.linkedin ? (
                            <img src={connected} alt="connected"/>
                        ) : (
                            <img src={notConnected} alt="not connected"/>)
                        }
                        </span>
                    </div>
                    <div className="network">
                        <img src={gmb} alt="logo gmb"/>
                        <span className="network-name">GOOGLE MY BUSINESS</span>
                        <span>{user.gmb ? (
                            <img src={connected} alt="connected"/>
                        ) : (
                            <img src={notConnected} alt="not connected"/>)
                        }
                        </span>
                    </div>
                </div>
            </>
        ) : (
            <p>Chargement...</p>
        )}
    </div>
}

export default Networks;