import React, {useEffect} from 'react';
import './App.css';
import {Outlet, useNavigate} from "react-router-dom";
import Header from "./header/Header";
import {Provider, useDispatch, useSelector} from "react-redux";
import {RootState, store} from "./store";
import { initializeUser } from "./userSlice";

function App() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state: RootState) => state.user.isAuthenticated);
    const isSsoUser = useSelector((state: RootState) => state.user.isSsoUser);
    const groupement = useSelector((state : RootState) => state.user.groupement);

    useEffect(() => {
        dispatch(initializeUser());
    }, [dispatch]);

    useEffect(() => {
        if (isAuthenticated !== undefined && !isSsoUser && (groupement && groupement.id === 1)) {
            navigate(isAuthenticated ? "/dashboard" : "/login");
        } else if (isAuthenticated !== undefined && isSsoUser) {
            navigate(isAuthenticated ? "/dashboard_agency" : "/login");
        } else if (groupement && groupement.id !== 1 && !isSsoUser) {
            navigate(isAuthenticated ? "/dashboard_admin" : "login");
        }
    }, [navigate, isAuthenticated, isSsoUser, groupement]);

    return (
        <Provider store={store}>
            <div className="app">
                <Header />
                <Outlet />
            </div>
        </Provider>
  );
}

export default App;
