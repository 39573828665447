import React, {useCallback, useEffect, useState} from 'react';
import "./lastPublications.css";
import { Link } from "react-router-dom";
import axios from "axios";
import lastPubliIcon from '../../../assets/lastPubli.svg';
import publicationsLogo from '../../../assets/publi.svg';
import Spinner from "../../../spinner/Spinner";
import mediaUrl from "../../../service/mediaUrl";
import {RootState} from "../../../store";
import {useSelector} from "react-redux";

interface Publication {
    media: string;
    extension: string;
    title: string;
    content: string;
    status: string;
    createdAt: string;
    publicationDate: string;
    id: number;
}

function LastPublications() {
    const [numberOfPublications] = useState(3);
    const [publications, setPublications] = useState<Publication[]>([]);
    let baseUrl = "/api/publications?status=2&order[publicationDate]=desc&itemsPerPage=" + numberOfPublications;
    const user = useSelector((state: RootState) => state.user);

    const loadPublications = useCallback(async (url: string)=> {
        let combinedPublications: Publication[] = [];
        try {
            const globalUrl = `${baseUrl}&isGlobal=true`;
            const globalResponse = await axios.get(globalUrl);
            combinedPublications = combinedPublications.concat(globalResponse.data["hydra:member"]);
            if (user.isSsoUser) {
                let userUrl = `${baseUrl}&createdByUser=${user.id}`;
                const userResponse = await axios.get(userUrl);
                combinedPublications = combinedPublications.concat(userResponse.data["hydra:member"]);
                if (user.groupement && user.groupement?.id !== 1) {
                    let groupementUrl = '';
                    groupementUrl = `${baseUrl}&groupement=${user.groupement?.id}`;
                    const groupementResponse = await axios.get(groupementUrl);
                    combinedPublications = combinedPublications.concat(groupementResponse.data["hydra:member"]);
                }
            } else {
                if (user.groupement?.id !== 1) {
                    let adminGrpUrl = `${baseUrl}&groupement=${user.groupement?.id}&groupement=1&createdByAdmin=${user.id}`;
                    const adminGrpResponse = await axios.get(adminGrpUrl);
                    combinedPublications = combinedPublications.concat(adminGrpResponse.data["hydra:member"]);
                } else {
                    let adminUrl = `${baseUrl}&groupement=1&createdByAdmin=${user.id}`;
                    const adminResponse = await axios.get(adminUrl);
                    combinedPublications = combinedPublications.concat(adminResponse.data["hydra:member"]);
                }
            }
            const uniquePublications = Array.from(new Map(combinedPublications.map(pub => [pub.id, pub])).values());
            const sortedPublications = uniquePublications.sort((a, b) => new Date(b.publicationDate).getTime() - new Date(a.publicationDate).getTime());
            setPublications(sortedPublications.slice(0, 3));
        } catch (error) {
            console.error(
                'Erreur lors de la récupération des dernières publications :',
                error
            );
        }
    }, [baseUrl, user.groupement, user.id, user.isSsoUser]);

    useEffect(() => {
        loadPublications(baseUrl);
    }, [baseUrl, loadPublications]);

    return (
        <div className="box">
            <div className="box-title">
                <img src={lastPubliIcon} alt="last publication icon"/>
                <div className="title">Dernières publications</div>
            </div>
            <Spinner loading={publications.length === 0}/>
            {publications.map((publi: any, index:any) => {
                const url = mediaUrl(publi);
                return <div key={index} className="publi-card">
                          <img src={url} alt="illustration" />
                          <div className="body">
                             <div className="body-title">{publi?.title}</div>
                             <div className="body-text">{publi?.content}</div>
                          </div>
                        </div>
            })}
            {publications.length > 0 && (
                <Link className="btn btn-block btn-lastpub align-center" to={"/publications"}>
                    <img src={publicationsLogo} alt="publications"/> Voir les publications
                </Link>
            )}
        </div>
    );
}

export default LastPublications;