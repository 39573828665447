import React, {useEffect, useState} from 'react';
import { handleExportClick } from "../../../service/csvExporter";
import "./publications.css";
import axios from "axios";
import doneLogo from "../../../assets/icondone.svg";
import waitLogo from "../../../assets/iconwait.svg";
import errorLogo from "../../../assets/iconerror.svg";
import downloadLogo from "../../../assets/download.svg";
import publicationLogo from "../../../assets/allPubli.svg";

const url = '/api/publications?pagination=false&isGlobal=true';
const urlCountError = 'count_failed';
const urlPublicationsByStatus = "publications_by_status";

function Publications() {
    const [published, setPublished] = useState(0);
    const [inProgress, setInProgress] = useState(0);
    const [onError, setOnError] = useState(0);
    const [loading, setLoading] = useState(false);
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    }

    async function fetchAllPublications(status?: number)
    {
        const response = await axios.get(urlPublicationsByStatus + `/${status}`, config);

        return response.data;
    }

    const headers =
        [
            "title",
            "id",
            "message",
            "network",
            "agencyName",
        ];

    const handleErrorClick = () => {
        handleExportClick(
            headers,
            fetchAllPublications,
            "publications_failed.csv",
            () => setLoading(true),
            () => setLoading(false),
            -1
        );
    }

    const handlePendingClick = () => {
        handleExportClick(
            headers,
            fetchAllPublications,
            "publications_pending.csv",
            () => setLoading(true),
            () => setLoading(false),
            0
        );
    }

    const handlePublishedClick = () => {
        handleExportClick(
            headers,
            fetchAllPublications,
            "publications_processed.csv",
            () => setLoading(true),
            () => setLoading(false),
            1
        );
    }

    useEffect(() => {
        ;(async () => {
            const response = await axios.get(url, config);

            const countFailed = await axios.get(urlCountError, config);

            let publi = 0;
            let error = countFailed.data.count ?? 0;
            let progress = 0;

            await response.data['hydra:member'].forEach((num: any, index: any) => {
                if (num.status === 2) {
                    publi ++;
                }
                if (num.status === 1) {
                    progress ++;
                }
            });

            setPublished(publi);
            setInProgress(progress);
            setOnError(error);
        })();
    });

    return (
        <div className="publication-smallBox">
            <div className="box-title">
                <img src={publicationLogo} alt="logo publication"/>
                <div className="title">
                    Publications
                </div>
            </div>
            <div className="groups">
                <div className="group-publish">
                    <div className="publication-stats">
                        <img src={doneLogo} alt="logo done"/>
                        <span className='publication-stats-title'>Publiées</span>
                        <span className='publication-stats-number'>{published}</span>
                        <button className="btn-download"
                                onClick={handlePublishedClick} disabled={loading}>
                            <img src={downloadLogo} alt="download logo"/>
                        </button>
                    </div>

                </div>
                <div className="group-progress">
                    <div className="publication-stats">
                        <img src={waitLogo} alt="logo waiting"/>
                        <span className='publication-stats-title'>En cours</span>
                        <span className='publication-stats-number'>{inProgress}</span>
                        <button className="btn-download"
                                onClick={handlePendingClick} disabled={loading}>
                            <img src={downloadLogo} alt="download logo"/>
                        </button>
                    </div>

                </div>
                <div className="group-error">
                    <div className="publication-stats">
                        <img src={errorLogo} alt="logo error"/>
                        <span className='publication-stats-title'>En erreur</span>
                        <span className='publication-stats-number'>{onError}</span>
                        <button className="btn-download"
                                onClick={handleErrorClick} disabled={loading}>
                            <img src={downloadLogo} alt="download logo"/>
                        </button>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Publications;