import React, { useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';
import { useDispatch } from "react-redux";
import { setUser } from "../userSlice";

const SsoLogin: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const userId = urlParams.get('userId');
        const url = 'validate-token';

        if (token) {
            const data = qs.stringify({token, userId});
            const config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': '',
                }
            };
            axios.post(url, data, config )
                .then(response => {
                    if (response.data.valid) {
                        const user = response.data.user;
                        const isAdmin = user.roles.includes("ROLE_ADMIN");

                        localStorage.setItem('userToken', token);
                        localStorage.setItem('userEmail', user.email);
                        localStorage.setItem('roles', JSON.stringify(user.roles));
                        localStorage.setItem('userId', user.id.toString());
                        localStorage.setItem('agencyName', user.agencyName);
                        localStorage.setItem('isAdmin', isAdmin);
                        localStorage.setItem('quota', user.quotaIA.toString());
                        localStorage.setItem('isSsoUser', "true");
                        localStorage.setItem('userMyId', user.userMyId);
                        localStorage.setItem('groupement', JSON.stringify(user.groupement));
                        localStorage.setItem('linkedToAgency', user.linkedToAgency.toString());

                        dispatch(setUser({
                            isAuthenticated: true,
                            isAdmin: isAdmin,
                            id: user.id,
                            email: user.email,
                            agencyName: user.agencyName,
                            token: token,
                            quota: user.quotaIA,
                            role: user.roles,
                            isSsoUser: true,
                            userMyId: user.userMyId,
                            groupement: user.groupement,
                            linkedToAgency: user.linkedToAgency
                        }));
                        navigate('/dashboard_agency');
                    } else {
                        navigate('/login');
                    }
                })
                .catch(error => {
                    console.error('Erreur de validation du token:', error);
                    navigate('/login');
                });
        } else {
            navigate('/login');
        }
    }, [dispatch, navigate]);

    return <div>Chargement...</div>;
};

export default SsoLogin;
